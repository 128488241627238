/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --color-primary: #1376bc;
  --color-secondary: #7f6563;
  --color-tertiary: #03adef;
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: var(--clr-grey-10);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

/* section */
.section {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}

/*
=============== 
Stripe Styles
===============
*/
.nav {
  height: 9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  position: relative;
  z-index: 1;
}
.nav-center {
  width: 90vw;
  max-width: var(--max-width);
}
.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-logo {
  width: 6rem;
}
.btn {
  font-size: 1rem;
  padding: 0.35rem 0.75rem;
  border-radius: var(--radius);
  border-color: transparent;
  color: white;
  background: var(--color-tertiary);
  cursor: pointer;
  transition: var(--transition);
  display: flex;
  align-items: center;
}
.btn:hover {
  background: var(--color-primary);
  color: white;
}
.nav-links {
  display: none;
}
.signin-btn {
  display: none;
}
.hero::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* background: url(./images/hero.svg); */
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 38%,
    rgba(229, 237, 241, 1) 58%,
    rgba(19, 118, 188, 1) 100%
  );
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}
.hero {
  position: relative;
  min-height: 100vh;
  margin-top: -9rem;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
}
.hero-center {
  width: 90vw;
  height: 90vh;
  max-width: var(--max-width);
  display: grid;
  align-items: center;
  margin-top: 5rem;
}

.hero-info h1 {
  text-transform: none;
  max-width: 500px;
  margin-bottom: 2rem;
  color: var(--color-primary);
}

.hero-info h3 {
  font-style: italic;
  color: var(--color-secondary);
}
.hero-info p {
  max-width: 35em;
  line-height: 1.8;
}
.hero-info .btn {
  display: inline-block;
  color: white;
  background-color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
}

.hero-info .btn:hover {
  background-color: white;
  color: var(--color-secondary);
}

.hero-images {
  display: none;
}

/* nav media query */
@media screen and (min-width: 800px) {
  .nav-center {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
  }
  .toggle-btn {
    display: none;
  }
  .signin-btn {
    display: none;
  }
  .nav-links {
    display: block;
    justify-self: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    text-align: center;
    height: 30%;
    display: grid;
    align-items: center;
  }
  .nav-links li {
    height: 100%;
  }
  .link-btn {
    height: 100%;
    background: transparent;
    border-color: transparent;
    font-size: 1.1rem;
    color: var(--color-primary);
    text-transform: capitalize;
    letter-spacing: 1px;
    width: 10rem;
  }
  /* .link-btn:hover,
  .link-btn:active {
    background-color: var(--color-primary);
    color: white;
  } */
}

/* hero media query */
@media screen and (min-width: 800px) {
  .hero::before {
    background-size: contain;
    height: 100%;
  }
  .hero-center {
    grid-template-columns: 2fr 1fr;
    height: 95vh;
  }

  .hero-info h1 {
    font-size: 3rem;
  }
  .hero-info p {
    font-size: 1.25rem;
  }
  .hero-images {
    display: block;
    justify-self: center;
  }
  .phone-img {
    width: 15rem;
  }
  .link-btn {
    width: 9rem;
  }
}

@media screen and (min-width: 1200px) {
  .hero-center {
    grid-template-columns: 2fr 1fr;
    align-items: end;
    padding-bottom: 12vh;
    height: 80vh;
    margin-top: 4rem;
  }
  .hero-info h1 {
    max-width: 100%;
    font-size: 5.5rem;
  }
  .hero-images {
    align-self: end;
  }
  .phone-img {
    width: 18rem;
  }
  .link-btn {
    width: 10rem;
    font-size: 1.3rem;
  }
}
@media screen and (min-width: 1400px) {
  .hero-center {
    padding-bottom: 20vh;
    height: 90vh;
  }
  .phone-img {
    width: 20rem;
  }
}

/* sidebar */
.sidebar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  visibility: hidden;
  z-index: -1;
  transition: var(--transition);
  transform: scale(0);
  background: rgba(0, 0, 0, 0.5);
}
.sidebar-wrapper.show {
  visibility: visible;
  z-index: 2;
  transform: scale(1);
}
.sidebar {
  width: 90vw;
  height: 95vh;
  max-width: var(--fixed-width);
  background: var(--clr-white);
  border-radius: var(--radius);
  box-shadow: var(--dark-shadow);
  position: relative;
  padding: 4rem 2rem;
  overflow: scroll;
}
.close-btn {
  font-size: 2rem;
  background: transparent;
  border-color: transparent;
  color: var(--clr-grey-5);
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}
.sidebar article {
  margin-bottom: 2rem;
}
.sidebar-sublinks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 0.25rem;
}
.sidebar-sublinks a {
  display: block;
  color: var(--color-primary);
  text-transform: capitalize;
  display: flex;
  align-items: center;
  margin: 0.2rem 0;
}
.sidebar-sublinks a:hover,
.sidebar-sublinks a:active {
  color: var(--color-primary);
}
.sidebar-sublinks svg {
  color: var(--clr-grey-5);
  margin-right: 1rem;
}

@media screen and (min-width: 800px) {
  .sidebar-wrapper {
    display: none;
  }
}

/* links */
.submenu {
  background: var(--clr-white);
  box-shadow: var(--dark-shadow);
  position: absolute;
  top: 4rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  display: none;
  padding: 2rem;
  border-radius: var(--radius);
  transition: var(--transition);
}
.submenu::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--clr-white);
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.submenu.show {
  display: block;
}

.submenu-center {
  display: grid;
  gap: 0.25rem 2rem;
}
.col-2 {
  grid-template-columns: repeat(2, 1fr);
}
.col-3 {
  grid-template-columns: repeat(3, 1fr);
}
.col-4 {
  grid-template-columns: repeat(4, 1fr);
}
.submenu h4 {
  margin-bottom: 1.5rem;
}
.submenu-center a {
  width: 10rem;
  /* display: block; */
  color: var(--color-primary);
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0.2rem 0.2rem;
}

.submenu-center a:hover {
  color: white;
  background-color: var(--color-primary);
  border-radius: var(--radius);
  padding: 0.2rem 0.2rem;
}

.submenu-center svg {
  color: var(--clr-grey-5);
  margin-right: 1rem;
}

/* Mission */
.mission_join {
  max-width: var(--max-width);
  padding: 4rem 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0rem auto;
  gap: 3rem;
}

.mission_join_title {
  color: var(--color-secondary);
}

.mission_join_body {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;
  max-width: var(--max-width);
  padding: 0rem 2rem;
}

.mission_join_text {
  padding: 0rem 2rem;
}

.mission_join .btn {
  display: inline-block;
  background-color: var(--color-secondary);
  margin-top: 2rem;
  border: 1px solid var(--color-secondary);
}

.mission_join .btn:hover {
  background-color: white;
  color: var(--color-secondary);
}

.join_title,
.mission_title {
  color: var(--color-secondary);
}

.join_list {
  padding: 0.1rem 0.1rem;
  display: flex;
  align-items: center;
  color: var(--clr-grey-5);
}
.join_list span {
  display: inline-block;
  margin-right: 0.4rem;
  color: var(--color-secondary);
}

.mission_photo {
  max-width: 26rem;
  display: inline-block;
  border-radius: var(--radius);
  box-shadow: var(--dark-shadow);
}

@media screen and (min-width: 900px) {
  .mission_join_body {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 800px) {
  .mission_join_body {
    margin: 0rem 0rem;
    padding: 0rem 0rem;
  }
  .mission_join {
    padding: 4rem 0rem;
  }
  .mission_photo {
    max-width: 28rem;
  }
}

/* Services */
.services_products_wrapper {
  background: var(--clr-primary-9);
}
.services_products {
  max-width: var(--max-width);
  padding: 4rem 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0rem auto;
  gap: 3rem;
}

.services_products_title {
  color: var(--color-secondary);
}

.services_products_body {
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;
}
.services_products_body a {
  background-color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
  position: absolute;
  bottom: 0.8rem;
}

.services_products_body a:hover {
  background-color: white;
  color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
}

.services_product {
  max-width: 20rem;
  padding: 2.8rem 1.8rem;
  padding-bottom: 4rem;
  border-radius: var(--radius);
  box-shadow: var(--dark-shadow);
  background-color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  /* justify-content: space-between; */
}

@media screen and (min-width: 900px) {
  .services_products_body {
    grid-template-columns: 1fr 1fr;
  }
}

/* Card */
.card {
  max-width: var(--max-width);
  width: 95%;
  padding: 0.4rem 0.7rem;
  border-radius: var(--radius);
  box-shadow: var(--dark-shadow);
  background-color: white;
  margin: 6rem auto;
  position: relative;
}

.card_title_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card_title {
  color: white;
  background: rgb(127, 101, 99);
  background: linear-gradient(
    83deg,
    rgba(127, 101, 99, 1) 0%,
    rgba(110, 193, 251, 1) 100%
  );
  font-size: 1rem;
  width: 90%;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  position: absolute;
  top: -3rem;
}

.card_content {
  color: var(--clr-grey-5);
  margin-top: 3rem;
}

@media screen and (min-width: 1200px) {
  .card_title {
    font-size: 1.2rem;
  }
}
/* Small Card*/

.small_card {
  max-width: var(--max-width);
  width: 95%;
  padding: 0.4rem 0.7rem;
  border-radius: var(--radius);
  box-shadow: var(--dark-shadow);
  background-color: white;
  margin: 2rem auto;
}

.small_card_title {
  color: var(--color-secondary);
}
.small_card_content {
  color: var(--clr-grey-5);
  display: flex;
  flex-direction: column;
}
.small_card_list {
  padding: 0.1rem 0.1rem;
  display: flex;
  align-items: center;
}
.small_card_list span {
  display: inline-block;
  margin-right: 0.4rem;
  color: var(--color-secondary);
}

.small_card_table {
  font-size: 0.75rem;
}

.small_card_table_header {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.small_card_table_header th {
  display: flex;
  justify-content: start;
  gap: 1rem;
}
.small_card_table_body {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  padding: 0.1rem;
}
.small_card_table_body td {
  display: flex;
  justify-content: start;
}

.small_card_table_header,
.small_card_table_header,
.small_card_table_body {
  border-bottom: 1px solid var(--color-secondary);
}

.small_card_table_body td {
  border-right: 1px solid var(--color-secondary);
}

.small_card_table_body td:first-of-type {
  border-left: 1px solid var(--color-secondary);
}

@media screen and (min-width: 600px) {
  .small_card_table {
    font-size: 0.875rem;
  }
}

@media screen and (min-width: 800px) {
  .small_card_table {
    font-size: 0.875rem;
  }
}

@media screen and (min-width: 1200px) {
  .small_card_table {
    font-size: 0.875rem;
  }
}

/* Synthesis Card */
.synthesis_group {
  margin: 6rem 2rem;
  padding: 1rem;
  border-left: 2px solid var(--color-secondary);
}
.synthesis_examples {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.synthesis_example {
  max-width: 20rem;
  padding: 0.5rem 1.8rem;
  border-radius: var(--radius);
  box-shadow: var(--dark-shadow);
  background-color: white;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

.synthesis_icon_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0;
}
.synthesis_icon {
  font-size: 4rem;
}

.synthesis_details {
  display: flex;
  flex-direction: column;
}
.synthesis_delimiter {
  align-self: center;
  width: 15rem;
  height: 5px;
  background: linear-gradient(
    83deg,
    rgba(127, 101, 99, 1) 0%,
    rgba(110, 193, 251, 1) 100%
  );
  margin: 0.2rem 0;
}
.synthesis_detail {
  display: flex;
  justify-content: space-between;
  padding: 0.2rem 0;
}

.synthesis_detail span {
  margin-right: 0.4rem;
}

/* Savings */
.savings_card_table {
  font-size: 0.75rem;
}
.savings_card_table_header {
  display: grid;
  grid-template-columns: minmax(70px, 1fr) repeat(5, minmax(10px, 1fr));
}
.savings_card_table_header th {
  display: flex;
  justify-content: start;
  gap: 1rem;
}
.savings_card_table_body {
  display: grid;
  gap: 1rem;
  grid-template-columns: minmax(70px, 1fr) repeat(5, minmax(10px, 1fr));
}
.savings_card_table_body td {
  display: flex;
  justify-content: start;
}

.savings_card_table_header,
.savings_card_table_header,
.savings_card_table_body {
  border-bottom: 1px solid var(--color-secondary);
}

.savings_card_table_body td {
  border-right: 1px solid var(--color-secondary);
}

.savings_card_table_body td:first-of-type {
  border-left: 1px solid var(--color-secondary);
}

@media screen and (min-width: 600px) {
  .savings_card_table {
    font-size: 0.875rem;
  }
}

@media screen and (min-width: 800px) {
  .savings_card_table {
    font-size: 0.875rem;
  }
}

@media screen and (min-width: 1200px) {
  .savings_card_table {
    font-size: 0.875rem;
  }
}

/* Contacts */

.contacts_wrapper {
  padding: 2rem 0;
}
.contacts_info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.contacts_title {
  color: var(--color-secondary);
  margin-bottom: 2rem;
}
.contacts_details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.4rem;
}
.contact_detail {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid var(--color-secondary);
  box-shadow: var(--dark-shadow);
}
.contact_detail p {
  margin: 2rem;
  color: var(--color-tertiary);
}

.contact_icon {
  margin-right: 1rem;
}

.contact_detail p:first-of-type {
  color: var(--color-secondary);
}

/* footer */
.footer {
  background: white;
}
.footer_menu {
  max-width: var(--max-width);
  padding: 2rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
}

.footer_links_group {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

.footer_links {
  display: flex;
  flex-direction: column;
}
.footer_item {
  color: var(--color-secondary);
  padding: 0.5rem 0.2rem;
}

.footer_item:hover {
  background-color: var(--color-secondary);
  color: var(--clr-grey-7);
}

@media screen and (min-width: 800px) {
  .footer_menu {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 1200px) {
  .footer_menu {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

/* Button Scroll to top */
.scrollToTopBtn {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--color-secondary);
  box-shadow: var(--dark-shadow);
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
}
.scrollToTopBtn:hover {
}

@media screen and (min-width: 1200px) {
  .scrollToTopBtn {
    right: 100px;
  }
}

@media screen and (min-width: 1350px) {
  .scrollToTopBtn {
    right: 150px;
  }
}
